// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xCJTv9JU{display:flex;overflow:hidden;position:relative}.xCJTv9JU:focus-within ._0SXLnHQK{opacity:1}.xCJTv9JU:focus-within .NQgUVxVt{opacity:1}.xCJTv9JU:focus-within .G-RrWDuB{opacity:1;transform:none}.xCJTv9JU:hover .NQgUVxVt,.xCJTv9JU:hover ._0SXLnHQK{opacity:1}.xCJTv9JU:hover .G-RrWDuB{opacity:1;transform:none}.pnxpZCDN{border-radius:max(1.41rem,min(2.08vw,2.5rem))}.pOgsml1J{aspect-ratio:2;border-radius:inherit;cursor:pointer;-o-object-fit:cover;object-fit:cover;width:100%}._0SXLnHQK{border-radius:inherit;box-shadow:inset 0 0 10rem rgba(0,0,0,.7);height:100%;left:0;top:0;width:100%}.NQgUVxVt,._0SXLnHQK{opacity:0;pointer-events:none;position:absolute;transition:.2s ease-in-out;transition-property:opacity}.NQgUVxVt{align-items:center;color:#fff;display:flex;justify-content:center;left:50%;top:50%;transform:translate(-50%,-50%)}.aK95EZX6{opacity:1}.Vi1PFNP7{--size:4rem}.G-RrWDuB{bottom:max(.88rem,min(1.3vw,1.56rem));color:#fff;opacity:0;position:absolute;right:max(1.51rem,min(2.24vw,2.69rem));transform:translateY(.5rem);transition:.2s ease-in-out;transition-property:opacity,transform}.YHFfXKX3,.G-RrWDuB{align-items:center;display:flex}.YHFfXKX3{height:2.63rem;justify-content:center;width:2.63rem}.Sq0X3Xyu{--size:1.5rem}.G-RrWDuB,.NQgUVxVt{filter:drop-shadow(0 0 .25rem rgba(0,0,0,.7))}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"xl": "1920.98",
	"container": "xCJTv9JU",
	"vignette": "_0SXLnHQK",
	"play": "NQgUVxVt",
	"controls": "G-RrWDuB",
	"containerRounded": "pnxpZCDN",
	"video": "pOgsml1J",
	"playVisible": "aK95EZX6",
	"playIcon": "Vi1PFNP7",
	"control": "YHFfXKX3",
	"controlIcon": "Sq0X3Xyu"
};
module.exports = ___CSS_LOADER_EXPORT___;
