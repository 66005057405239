
import Vue from "vue"
import type { SwiperOptions } from "swiper"
import type { ProgramEvent } from "@/types/api/program"
import breakpoints from "@/assets/style/_breakpoints.scss"

export default Vue.extend({
  data: () => ({
    events: [] as ProgramEvent[],
  }),

  async fetch () {
    const [res, err] = await this.$api.program.getEvents({
      params: {
        "filter[type]": "online",
        "paginator[limit]": 1000,
      },
    })
    if (err) console.error(err)
    else this.events = this.$api.helpers.getList(res)
  },

  computed: {
    swiperOptions (): SwiperOptions {
      return {
        spaceBetween: 20,
        slidesPerView: "auto",
        breakpoints: {
          [breakpoints.md]: {
            slidesPerView: 3,
          },
        },
      }
    },
  },
})
