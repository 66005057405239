// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QAjSojP8>*+*{margin-top:max(2rem,min(3.33vw,4rem))}.QAjSojP8 .M\\+9Yn5ox{font-size:.75rem;pointer-events:none;width:-moz-max-content;width:max-content}.OPLR60VT .swiper-container{--x:var(--p-x);--y:calc((100vw - var(--content-width))/2);padding:0 max(var(--x),var(--y))}.J5iVVoXc{height:auto!important;min-height:30rem;padding:2px}@media(max-width:1229.98px){.J5iVVoXc{min-height:max(16.88rem,min(25vw,30rem));width:max(20rem,min(21.51vw,25.81rem))!important}}.J5iVVoXc>*{height:100%}.yYHknpBR{background:#32388c;border-radius:max(1.41rem,min(2.08vw,2.5rem));color:#fff;display:flex;flex-direction:column;justify-content:space-between;overflow:hidden;padding:max(1.41rem,min(2.08vw,2.5rem));position:relative;transition:.2s ease-in-out;transition-property:transform}.yYHknpBR:active{transform:scale(.95)}.yYHknpBR>*{position:relative}._0EoDr4Mz{border-radius:inherit;height:100%;left:0;mix-blend-mode:multiply;-o-object-fit:cover;object-fit:cover;pointer-events:none;position:absolute;top:0;-webkit-user-select:none;-moz-user-select:none;user-select:none;width:100%}.VfAr5t9c{font-size:.75rem;font-weight:600;line-height:1.2;margin-bottom:max(1.13rem,min(1.67vw,2rem));overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.AQK84kQh>*+*{margin-top:max(.84rem,min(1.25vw,1.5rem))}.yc3r\\+z1O{font-size:max(1.13rem,min(1.04vw,1.25rem));letter-spacing:.02em;line-height:1.1;text-transform:uppercase}._2UCEOHSD,.yc3r\\+z1O{text-wrap:balance;word-wrap:break-word;display:-webkit-box;-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis;-webkit-line-clamp:5}._2UCEOHSD{font-size:.88rem;font-weight:500;line-height:1.2}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"xl": "1920.98",
	"container": "QAjSojP8",
	"eventBtn": "M+9Yn5ox",
	"swiper": "OPLR60VT",
	"swiperSlide": "J5iVVoXc",
	"event": "yYHknpBR",
	"eventBg": "_0EoDr4Mz",
	"eventTag": "VfAr5t9c",
	"eventFooter": "AQK84kQh",
	"eventTitle": "yc3r+z1O",
	"eventText": "_2UCEOHSD"
};
module.exports = ___CSS_LOADER_EXPORT___;
